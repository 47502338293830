<template>
  <div class="count-sample">
    <div class="content">
      <div class="check-nav" @click="handleNavToCheck">查看检验清单</div>
      <card>
        <template v-slot:title>
          <div>
            <div class="title-text">{{ articleInfo ? articleInfo.articleNo : '' }}</div>
            <div class="sub-title" style="color: #797C80">{{ articleInfo ? articleInfo.articleNoDesc : '' }}</div>
          </div>
        </template>
        <div class="info-item space-between">
          <div class="label">订单号</div>
          <div class="value">{{ orderNum }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">订单数量</div>
          <div class="value">{{ articleInfo && articleInfo.applicationQuantity }}</div>
        </div>
        <!--        <div class="info-item space-between">-->
        <!--          <div class="label">可验货数量比例</div>-->
        <!--          <div class="value">{{ articleInfo.inspectableProportion }}%</div>-->
        <!--        </div>-->
        <field-item label="完成数量" required>
          <div>
            <!--            <div class="tip">（可验货完成数量比例 ：{{completePercent}}）</div>-->
            <div class="tip">（可验货完成数量比例 ：{{ articleInfo.inspectableProportion }}%）</div>
            <div class="vertical-center">
              <counter v-model="form.completedQuantity" :disabled="disabled" @change="activeSaveButton"/>
            </div>
          </div>
        </field-item>
        <span style="color: #055C9D; margin-left: 84px" @click="handleSkip">跳过点数步骤？</span>
        <field-item label="抽检数量" required style="margin-top: 16px">
          <div>
            <div class="tip">
              （抽样水平{{ articleInfo.samplingLevel }},建议至少抽检{{ articleInfo.suggestedQuantity }}个）
            </div>
            <counter v-model="form.samplingQuantity" :disabled="disabled" @change="activeSaveButton"/>
          </div>
        </field-item>
        <field-item label="上传照片">
          <div>
            <div class="tip">（仓库、堆箱、抽样等照片）</div>
            <image-uploader
              v-model="form.storehousePictures"
              :show-upload="!disabled"
              :deletable="!disabled"
              @input="activeSaveButton"
            />
          </div>
        </field-item>
        <!--        <field-item label="抽样照片">-->
        <!--          <div class="space-between">-->
        <!--            <ImageUploader-->
        <!--                v-model="form.samplePictures"-->
        <!--                :show-upload="!disabled"-->
        <!--                :deletable="!disabled"-->
        <!--                @input="activeSaveButton"-->
        <!--            />-->
        <!--          </div>-->
        <!--        </field-item>-->
        <!--        <field-item label="备注">-->
        <!--          <el-input-->
        <!--              v-model="form.remarks"-->
        <!--              placeholder="请输入"-->
        <!--              type="textarea"-->
        <!--              :rows="3"-->
        <!--              :disabled="disabled"-->
        <!--              maxLength="100"-->
        <!--              @blur="handleInputBlur"-->
        <!--          />-->
        <!--        </field-item>-->
      </card>
    </div>
    <div v-if="!disabled" class="fixed-btn">
      <div class="btn-box space-between vertical-center">
        <van-button
          :loading="saveLoading"
          :disabled="isSaveBtnDisabled || saveLoading"
          loading-text="保存中"
          class="btn"
          size="large"
          color="#055C9D"
          type="primary"
          @click="handleSave"
        >
          保存
        </van-button>
      </div>
    </div>
    <SkipDialog
      ref="skipDialog"
      :order-id="orderId"
      :article-id="articleId"
    />
    <OkConfirmDialog ref="OkConfirmDialog" @onConfirm="onOkSaveConfirm"/>
    <StopConfirmDialog
      ref="StopConfirmDialog"
      :confirm-button-text="from === 'result' ? '确定' : '保存并继续验货'"
      :cancel-button-text="from === 'result' ? '取消' : '提交验货结论'"
      :message-text="from === 'result' ? '您的验货任务已完成，但未达到点数抽样要求的标准' : '未达到点数抽样要求的标准。不建议继续验货，确认后将向系统提交“未验货”结论。'"
      @onConfirm="onStopConfirm"
      @onCancel="onStopCancel"
    />
    <SaveConfirmDialog
      ref="SaveConfirmDialog"
      :article-info="articleInfo"
      :form="form"
      :order-num="orderNum"
      :complete-percent="completePercent"
      @onSaveConfirm="onSaveConfirm"
      @onStopConfirmNew="onStopConfirmNew"
    ></SaveConfirmDialog>
    <check-result-dialog
      ref="checkResultDialog"
      @on-ok="onCheckResultOk"
    />
  </div>
</template>

<script>
import Card from '@/components/Common/Card';
import {getAction, putAction} from '@/api/manage';
import {
  SAMPLING_LEVEL_DICT,
  ARTICLE_NO_PROCESSING,
  ARTICLE_NO_WAITING_CERTAIN,
  ARTICLE_CONCLUSION_NOT,
  taskStatusObj, ARTICLE_CONCLUSION_AGREE, ARTICLE_NO_COMPLETED, SUPPLIER_CONCLUSION_AGREE
} from '@/constants';
import {InbounceMixin} from '@/mixins/InbounceMixin';
import FieldItem from '@/components/Common/FieldItem';
import Counter from '@/components/Common/Counter';
import SkipDialog from '@/components/V2/CountSample/SkipDialog';
import OkConfirmDialog from "@/components/V2/CountSample/OkConfirmDialog.vue";
import StopConfirmDialog from "@/components/V2/CountSample/StopConfirmDialog.vue";
import ImageUploader from '@/components/Common/ImageUploader';
import SaveConfirmDialog from "@/components/V2/CountSample/SaveConfirmDialog.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {cloneDeep} from 'lodash'
import {getRandomId, imgStrToList} from "@/utils/util";
import moment from "moment";
import {isNative} from "@/config";
import {ORDER_WAITING_START} from "@/constants";
import CheckResultDialog from "@/components/V2/Check/CheckResultDialog.vue";

export default {
  name: "countSample",
  mixins: [InbounceMixin],
  components: {
    CheckResultDialog,
    Card,
    FieldItem,
    Counter,
    SkipDialog,
    OkConfirmDialog,
    StopConfirmDialog,
    ImageUploader,
    SaveConfirmDialog
  },
  data() {
    return {
      orderId: '',
      articleId: '',
      articleInfo: {},
      SAMPLING_LEVEL_DICT,
      saveLoading: false,
      form: {
        completedQuantity: null,
        samplingQuantity: null,
        storehousePictures: [],
        samplePictures: [],
        remarks: '',
        skipReason: '',
      },
      from: '',
      isSaveBtnDisabled: true,
      conclusion: null,
      conclusionReason: null,
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
    orderNum() {
      const orderList = this.taskInfo?.orderList || []
      const order = orderList.find(item => item.id === this.orderId)
      return order?.orderNum
    },
    completePercent() {
      const completedQuantity = parseInt(this.form?.completedQuantity || 0)
      const applicationQuantity = parseInt(this.articleInfo?.applicationQuantity || 0)
      if (applicationQuantity !== 0) {
        const percent = completedQuantity * 100 / applicationQuantity
        return `${parseInt(percent)}%`
      }
      return ''
    },
    disabled() {
      return this.taskInfo?.status === taskStatusObj.TASK_STATUS_APPLY_CANCEL
    },
  },
  async created() {
    this.orderId = this.$route.query.orderId
    this.articleId = this.$route.query.articleId
    this.from = this.$route.query.from
    this.SET_TASK_ID(this.$route.query.taskId)
    await this.getTaskInfo()
    this.getArticleInfo()
    this.initForm()
    if(this.from === 'check') {
      this.activeSaveButton()
    }
  },
  methods: {
    ...mapMutations(['SET_TASK_ID']),
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    handleInputBlur() {
      window.scroll(0, 0);
    },
    handleSkip() {
      this.$refs.SaveConfirmDialog.open('SKIP')
      // this.$refs.skipDialog.open()
    },
    onOkSaveConfirm() {
      this.goOnSubmit()
    },
    async goOnSubmit() {
      const taskInfo = await this.getSubmitData(ARTICLE_NO_PROCESSING)
      this.saveLoading = true
      const res = await this.saveTaskInfo(taskInfo)
      this.saveLoading = false
      if (res.success) {
        if (this.$route.query.from === 'check' || this.$route.query.from === 'result') {
          this.$router.back()
        } else {
          this.$router.replace({
            path: '/check',
            query: {
              ...this.$route.query
            },
          })
        }
      }
    },
    async onStopConfirm() {
      const taskInfo = await this.getSubmitData(ARTICLE_NO_WAITING_CERTAIN)
      const res = await this.saveTaskInfo(taskInfo)
      // const res = {success: true}
      if (res.success) {
        if (this.$route.query.from === 'check') {
          this.$router.go(-2)
        } else if (this.$route.query.from === 'result') {
          this.$router.go(-1)
        } else {
          this.$router.back()
        }
      } else this.Toast.fail(res.message)
    },
    onStopCancel() {
      if (this.$route.query.from !== 'result') {
        this.goOnSubmit()
      }
    },
    onStopConfirmNew(data) {
      this.form.remarks = data.remarks
      this.form.skipReason = data.skipReason
      this.$refs.checkResultDialog.open()
    },
    async onSaveConfirm(data) {
      if (data?.type === 'SKIP') {
        this.form.completedQuantity = null
        this.form.samplingQuantity = null
        this.form.storehousePictures = []
      }
      this.form.remarks = data.remarks
      this.form.skipReason = data.skipReason
      const taskInfo = await this.getSubmitData(data.status)
      const res = await this.saveTaskInfo(taskInfo)
      if (res.success) {
        if (this.$route.query.from === 'check' || this.$route.query.from === 'result') {
          this.$router.back()
        } else {
          this.$router.replace({
            path: '/check',
            query: {
              ...this.$route.query
            },
          })
        }
      } else this.Toast.fail(res.message)
    },
    async onCheckResultOk(form) {
      this.conclusion = form.result
      this.conclusionReason = form.reason
      const articleStatus = form.result === ARTICLE_CONCLUSION_AGREE ? ARTICLE_NO_COMPLETED : ARTICLE_NO_WAITING_CERTAIN
      const taskInfo = await this.getSubmitData(articleStatus)
      const res = await this.saveTaskInfo(taskInfo)
      this.conclusion = ''
      this.conclusionReason = ''
      if (res.success) {
        this.$router.replace({
          path: '/orderList',
          query: {
            ...this.$route.query
          },
        })
      }
    },
    async getSubmitData(articleStatus) {
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.status = articleStatus
      article.completedQuantity = this.form.completedQuantity
      article.samplingQuantity = this.form.samplingQuantity
      article.storehousePictures = this.form.storehousePictures.map(item => item.url).join(',')
      article.samplePictures = this.form.samplePictures.map(item => item.url).join(',')
      article.remarks = this.form.remarks
      article.skipReason = this.form.skipReason
      if (this.conclusion) {
        article.conclusion = this.conclusion
        article.conclusionReason = this.conclusionReason
        article.supplierConfirm = this.conclusion === ARTICLE_CONCLUSION_AGREE ? SUPPLIER_CONCLUSION_AGREE : ''
        article.finishTime = moment().format('YYYY-MM-DD HH:mm:ss')
        article.conclusionTime = moment().format('YYYY-MM-DD HH:mm:ss')
        let user = 'H5'
        let userId = ''
        if (isNative) {
          try {
            const res = await window.nativeApp.getUserInfo()
            if (res.success) {
              const result = JSON.parse(res.result)
              userId = result.userId
              user = result.user
              console.log(res)
            }
          } catch (e) {
            console.log('获取用户信息出错：')
            console.error(e)
          }
        }
        article.conclusionList.push({
          id: getRandomId(),
          role: "INSPECTOR",
          user,
          userId,
          submitTime: moment().format('YYYY-MM-DD HH:mm:ss'),
          conclusion: this.conclusion
        })
        // article.conclusion = ARTICLE_CONCLUSION_NOT
      }
      return taskInfo
    },
    isFormValid() {
      if (!this.form.completedQuantity && this.form.completedQuantity !== 0) {
        this.Toast(`请输入完成数量`)
        return false
      }
      // if (this.articleInfo.applicationQuantity !== null && this.form.completedQuantity > this.articleInfo.applicationQuantity) {
      //   this.Toast('完成数量不可超过申请数量')
      //   return false
      // }
      // if (this.form.samplingQuantity === 0) {
      //   this.Toast(`抽检数量不能为0`)
      //   return false
      // }
      if (!this.form.samplingQuantity && this.form.samplingQuantity !== 0) {
        this.Toast(`请输入抽检数量`)
        return false
      }
      // if (this.form.samplingQuantity > this.form.completedQuantity) {
      //   this.Toast('抽检数量不可超过完成数量')
      //   return false
      // }
      if (this.form.storehousePictures.find(item => !item.url)) {
        this.Toast(`请等待图片上传完成`)
        return false
      }
      if (this.form.samplePictures.find(item => !item.url)) {
        this.Toast(`请等待图片上传完成`)
        return false
      }
      return true
    },
    handleSave() {
      const formValid = this.isFormValid()
      if (formValid) {
        let type = 'OK'
        // 完成数量 > 申请数量  --提示超过订单数
        if (this.form.completedQuantity > this.articleInfo.applicationQuantity) {
          type = 'OVER'
        }
        // 完成数量 < 可验货比例数量  --提示未达到要求
        if (this.form.completedQuantity * 100 < (parseInt(this.articleInfo.applicationQuantity) || 0) * (parseFloat(this.articleInfo.inspectableProportion))) {
          type = 'NOTOK'
        }
        this.$refs.SaveConfirmDialog.open(type)
        // const isCompletedQuantityOk = (parseInt(this.articleInfo.applicationQuantity) || 0) * (parseFloat(this.articleInfo.inspectableProportion)) <= this.form.completedQuantity * 100
        // const isSamplingQuantityOk = this.form.samplingQuantity >= parseInt(this.articleInfo.suggestedQuantity)
        // if (isCompletedQuantityOk && isSamplingQuantityOk) {
        //   if (this.from === 'result') {
        //     this.goOnSubmit()
        //   } else {
        //     this.$refs.OkConfirmDialog.open()
        //   }
        // } else {
        //   this.$refs.StopConfirmDialog.open()
        // }
      }
    },
    getArticleInfo() {
      const order = this.taskInfo.orderList.find(item => item.id === this.orderId)
      this.articleInfo = order.articleNoList.find(item => item.id === this.articleId) || {}
    },
    initForm() {
      if (this.articleInfo.status === ORDER_WAITING_START) {
        this.form.completedQuantity = null
        this.form.samplingQuantity = null
      } else {
        this.form.completedQuantity = this.articleInfo?.completedQuantity || this.articleInfo?.completedQuantity === 0 ? parseInt(this.articleInfo?.completedQuantity) : null
        this.form.samplingQuantity = this.articleInfo?.samplingQuantity ||  this.articleInfo?.samplingQuantity === 0 ? parseInt(this.articleInfo?.samplingQuantity) : null
      }
      this.form.storehousePictures = imgStrToList(this.articleInfo?.storehousePictures)
      this.form.samplePictures = imgStrToList(this.articleInfo?.samplePictures)
      this.form.remarks = this.articleInfo?.remarks
      this.form.skipReason = this.articleInfo?.skipReason || ''
    },
    handleNavToCheck() {
      this.$router.push({
        path: '/check',
        query: {...this.$route.query, disabled: '1'},
      })
    },
    activeSaveButton() {
      if(this.from === 'check') {
        this.isSaveBtnDisabled = false
        return
      }
      const isCompleteNotOk = !this.form.completedQuantity && this.form.completedQuantity !== 0
      const isSamplingNotOk = !this.form.samplingQuantity && this.form.samplingQuantity !== 0
      if (isCompleteNotOk || isSamplingNotOk) {
        this.isSaveBtnDisabled = true
      } else {
        this.isSaveBtnDisabled = false
      }
    },
  },
}
</script>

<style scoped lang="less">
.count-sample {
  .content {
    padding: 1px 16px 156px;
    background: #F9F9F9;
    height: calc(100vh - 156px);
    overflow-y: auto;
    position: relative;

    .check-nav {
      position: absolute;
      top: 34px;
      right: 40px;
      color: #055C9D;
    }

    ::v-deep {
      .title-text {
        width: calc(100vw - 150px);
      }
    }

    .tip {
      font-size: 12px;
      color: #AAB3BA;
    }

    .info-item {
      line-height: 20px;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .label {
        width: 66px;
        font-size: 12px;
        color: #AAB3BA;
      }

      .value {
        width: calc(100% - 76px);
        font-size: 14px;
        word-break: break-all;
        color: #4B4E51;
      }
    }

    ::v-deep {
      .el-textarea__inner {
        font-size: 14px;
        color: #AAB3BA;
      }
    }
  }

  .fixed-btn {
    padding: 11px 16px 16px;

    .top-btn-box {
      margin-top: 0 !important;
      margin-bottom: 22px;

      .btn {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .btn-box {
      .van-button {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="less">
.vant-dialog-confirm-blue {
  .van-dialog__message, .message {
    font-size: 16px;
    color: #4B4E51;
    line-height: 22px;
    text-align: left;
  }
}
</style>
