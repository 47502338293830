<template>
  <van-dialog
    v-model="visible"
    :show-cancel-button="isShowCancelBtn"
    show-confirm-button
    confirm-button-color="#055C9D"
    :confirm-button-text="confirmButtonText"
    cancel-button-color="#4B4E51"
    :cancel-button-text="cancelButtonText"
    className="vant-dialog-confirm-blue"
    :beforeClose="onBeforeClose"
    close-on-click-overlay
  >
    <div class="dialog-content">
      <van-icon name="cross" class="close-icon" @click="visible = false"/>
      <div class="tip-box">
        <img :src="tipObj[type].img" alt="">
        <div class="tip">{{ tipObj[type].tip }}</div>
      </div>
      <div class="title-box">
        <div class="title-text">{{ articleInfo.articleNo }}</div>
        <div class="sub-title">{{ articleInfo.articleNoDesc }}</div>
      </div>
      <div class="item">
        <div class="label">订单号</div>
        <div class="value">{{ orderNum }}</div>
      </div>
      <div class="item">
        <div class="label">订单数量</div>
        <div class="value">{{ articleInfo.applicationQuantity }}</div>
      </div>
      <div class="item">
        <div class="label">可验货比例</div>
        <div class="value">{{ articleInfo.inspectableProportion }}%</div>
      </div>
      <div class="item">
        <div class="label">完成数量</div>
        <div v-if="type === 'SKIP'" class="value">未点数</div>
        <div v-else class="value">{{ form.completedQuantity }}（{{ completePercent }}）</div>
      </div>
      <div class="item">
        <div class="label">抽检数量</div>
        <div v-if="type !== 'SKIP'" class="value">{{ form.samplingQuantity }}</div>
      </div>
      <div class="item">
        <div class="label">备注</div>
        <div class="value">
          <template v-if="type === 'SKIP'">
            <div class="tip">跳过“点数抽样”步骤？请填写原因</div>
            <el-form ref="form" :model="formData" :rules="rules">
              <el-form-item prop="skipReason">
                <el-input
                  v-model="formData.skipReason"
                  placeholder="请输入"
                  type="textarea"
                  :rows="5"
                  maxLength="100"
                />
              </el-form-item>
            </el-form>
          </template>
          <el-input
            v-else
            v-model="remarks"
            placeholder="请输入"
            type="textarea"
            :rows="5"
            maxLength="100"
          />
        </div>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import {ARTICLE_NO_PROCESSING} from "@/constants";

const green = require('@/assets/svg/icon-tip-green.svg')
const blue = require('@/assets/svg/icon-tip-blue.svg')
const yellow = require('@/assets/svg/icon-tip-yellow.svg')
const red = require('@/assets/svg/icon-tip-red.svg')

export default {
  name: "SaveConfirmDialog",
  props: {
    orderNum: {
      type: String,
      default: '',
    },
    completePercent: {
      type: String,
      default: '',
    },
    articleInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    form: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      visible: false,
      remarks: '',
      cancelButtonText: '保存并继续验货',
      type: 'SKIP',
      formData: {
        skipReason: ''
      },
      rules: {
        skipReason: {required: true, message: "请填写原因"}
      },
      tipObj: {
        'SKIP': {
          img: blue,
          tip: '跳过点数步骤',
        },
        'OK': {
          img: green,
          tip: '达到可验货比例',
        },
        'OVER': {
          img: red,
          tip: '超过订单数量',
        },
        'NOTOK': {
          img: yellow,
          tip: '未达到可验货比例',
        },
      },
    }
  },
  computed: {
    isShowCancelBtn() {
      return this.type === 'NOTOK'
    },
    confirmButtonText() {
      return this.isShowCancelBtn ? '提交验货结论' : '保存并继续验货'
    },
  },
  methods: {
    open(type) {
      this.visible = true
      this.type = type
      this.remarks = this.form.remarks
      if (type === 'SKIP') {
        this.formData.skipReason = this.form.skipReason
      } else {
        this.formData.skipReason = ''
      }
    },
    onBeforeClose(action, down) {
      if(action === 'overlay') {
        down()
        return
      }
      if (this.type === 'SKIP') {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.$emit('onSaveConfirm', {
              status: ARTICLE_NO_PROCESSING,
              skipReason: this.formData.skipReason,
              remarks: '',
              type: this.type,
            })
            down()
          } else {
            down(false)
          }
        })
      } else if (this.isShowCancelBtn && action === 'confirm') {
        down()
        this.$emit('onStopConfirmNew', {
          status: ARTICLE_NO_PROCESSING,
          skipReason: '',
          remarks: this.remarks,
        })
      } else {
        this.$emit('onSaveConfirm', {
          status: ARTICLE_NO_PROCESSING,
          skipReason: '',
          remarks: this.remarks,
          type: this.type,
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 24px 24px 20px;
  position: relative;

  .close-icon {
    position: absolute;
    top: 35px;
    right: 28px;
  }

  .tip-box {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    .tip {
      font-size: 16px;
      color: #4B4E51;
    }
  }

  .title-box {
    .title-text {
      font-size: 16px;
      color: #4B4E51;
    }

    .sub-title {
      font-size: 12px;
      color: #797C80;
      margin-top: 7px;
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    margin-top: 17px;

    .label {
      width: 76px;
      font-size: 12px;
      color: #AAB3BA;
      flex-shrink: 0;
    }

    .value {
      font-size: 14px;
      color: #4B4E51;

      .tip {
        font-size: 12px;
        color: #BBBEC1;
        text-align: right;
        margin-bottom: 10px;
      }
    }

    .el-input {
      font-size: 14px;
      color: #AAB3BA;
    }
  }
}
</style>