<template>
  <van-dialog
    v-model="visible"
    show-cancel-button
    confirm-button-color="#055C9D"
    confirm-button-text="提交"
    cancel-button-text="取消"
    cancel-button-color="#4B4E51"
    className="vant-dialog-confirm-blue"
    :beforeClose="onBeforeClose"
  >

    <div class="dialog-content">
      <div class="message">跳过“点数抽样"步骤？请写原因：</div>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item prop="skipReason">
          <el-input
            v-model="form.skipReason"
            placeholder="请填写原因"
            type="textarea"
            :rows="4"
            :maxlength="100"
          />
        </el-form-item>
      </el-form>
    </div>
  </van-dialog>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {cloneDeep} from "lodash";
import {ARTICLE_NO_PROCESSING} from "@/constants";

export default {
  name: "SkipDialog",
  props: {
    orderId: {
      type: String,
      default: ''
    },
    articleId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      form: {
        skipReason: ''
      },
      rules: {
        skipReason: {required: true, message: "请填写原因"}
      }
    }
  },
  computed: {
    ...mapGetters(['taskInfo']),
  },
  methods: {
    ...mapActions(['getTaskInfo', 'saveTaskInfo']),
    open() {
      this.visible = true
      this.form.skipReason = ''
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    onBeforeClose(action, down) {
      if (action === 'confirm') {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.onSkipSaveConfirm()
          } else {
            down(false)
          }
        })
      } else {
        down()
      }
    },
    async onSkipSaveConfirm() {
      const taskInfo = cloneDeep(this.taskInfo)
      const order = taskInfo.orderList.find(item => item.id === this.orderId)
      order.updateFlag = 'Y'
      const article = order.articleNoList.find(item => item.id === this.articleId)
      article.updateFlag = 'Y'
      article.skipReason = this.form.skipReason
      article.status = ARTICLE_NO_PROCESSING
      const res = await this.saveTaskInfo(taskInfo)
      if (res.success) {
        this.$router.replace({
          path: '/check',
          query: {
            ...this.$route.query
          },
        })
      } else this.Toast.fail(res.message)
    },
  },
}
</script>

<style scoped lang="less">
.dialog-content {
  padding: 48px 24px 0;

  .message {
    margin-bottom: 20px;
  }

  ::v-deep {
    .el-textarea__inner {
      background: #FFFFFF;
      border: 1px solid #F1F2F6;
      border-radius: 4px;
    }
  }
}
</style>
