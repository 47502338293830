<template>
  <van-dialog
    v-model="visible"
    :show-cancel-button="false"
    confirm-button-color="#055C9D"
    confirm-button-text="确认并继续验货"
    className="vant-dialog-confirm-blue"
    :beforeClose="onBeforeClose"
  >
    <div class="dialog-content">
      <img src="@/assets/img/icon_ok.png" alt="" class="ok-img">
      <div class="message">达到点数抽样要求的标准。</div>
    </div>
  </van-dialog>
</template>

<script>

export default {
  name: "OkConfirmDialog",
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    onBeforeClose(action, down) {
      down()
      if (action === 'confirm') {
        this.$emit('onConfirm')
      }
    },
  },
}
</script>

<style scoped lang="less">
.dialog-content {
  padding: 48px 28px 20px;
  display: flex;
  align-content: center;

  .ok-img {
    width: 23px;
    height: 23px;
    margin-right: 13px;
  }

  .message {
    margin-bottom: 20px;
  }
}
</style>
