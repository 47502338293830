<template>
  <van-dialog
    v-model="visible"
    show-cancel-button
    confirm-button-color="#055C9D"
    :confirm-button-text="confirmButtonText"
    cancel-button-color="#4B4E51"
    :cancel-button-text="cancelButtonText"
    className="vant-dialog-confirm-blue"
    :beforeClose="onBeforeClose"
  >
    <div class="dialog-content">
      <img src="@/assets/img/icon_warning.png" alt="" class="warning-img">
      <div class="message">{{ messageText}}</div>
    </div>
  </van-dialog>
</template>

<script>

export default {
  name: "StopConfirmDialog",
  props: {
    confirmButtonText: {
      type: String,
      default: ''
    },
    cancelButtonText: {
      type: String,
      default: ''
    },
    messageText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    onBeforeClose(action, down) {
      down()
      if (action === 'confirm') {
        this.$emit('onConfirm')
      } else {
        this.$emit('onCancel')
      }
    },
  },
}
</script>

<style scoped lang="less">
.dialog-content {
  padding: 48px 28px 20px;
  display: flex;
  align-content: center;

  .warning-img {
    width: 23px;
    height: 23px;
    margin-right: 13px;
  }

  .message {
    margin-bottom: 20px;
  }
}
</style>
